<template>
    <div>
        <div class="about-us-top np-padding">
            <div class="about-us-in">
                <div class="about-left navigation-button">
                    <h1>Become a Driver</h1>
                    <p>Our drivers are considered our heroes because they are essential in our quest to deliver premium services to customers.</p>
                    <div class="about-left-btn">
                    <a href="https://intelliapp.driverapponline.com/c/seattlechoicetransport" target="_blank">Apply now</a>
                    </div>
                </div>
                <div class="about-right" :style="{ backgroundImage: 'url(' + require(`@/assets/images/become-seller.png`) + ')'}"></div>
            </div>
        </div>
        <div class="about-design become-design">
            <div class="container">
                <div class="mecome-design-main">
                    <h2>How we operate</h2>
                    <p>At Seattcle choice, We value excellent service and top notch delivery etiquette.</p>
                    <div class="become-box">
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/Graph.png" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Our Drivers</h3>
                                <p>We hire only competent, professional, and reliable drivers and reward their labor with a salary that provides stability. Our incentive program prioritizes safety and process over mere output.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/Graph.png" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Our Team</h3>
                                <p>We put into service a devoted team of logistics professionals who are detailed, and specialize in developing innovative solutions to complex problems in emergency situations.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/Graph.png" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Customer Experience</h3>
                                <p>We are regularly appraised by customers, for our readiness to go the extra mile just to provide logistical aid whenever they need it.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-design become-design why-work">
            <div class="container">
                <div class="mecome-design-main">
                    <h2>Why work with Us</h2>
                    <p>We are driven by a set of core values which shapes and defines the disposition and culture of our company. Our core values include;</p>
                    <div class="become-box">
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/tick-square.svg" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Safety</h3>
                                <p>We prioritize the safety of our drivers, teams, and our customers. Every step in prevention of accIidents is critical.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/tick-square.svg" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Employee Excellence</h3>
                                <p>The growth of our employees is vital to us. We provide an environment that allows them to thrive.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/tick-square.svg" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Organizational Excellence</h3>
                                <p>We are performance-driven and are therefore devoted to attain the peak of excellence in every of our services.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/tick-square.svg" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Customer Service</h3>
                                <p>We exist for our customers. We foster lasting relationships with our customers by offering the best services.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/tick-square.svg" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Integrity</h3>
                                <p>It is our responsibility to be integritous in every step and decision we make.</p>
                            </div>
                        </div>
                        <div class="become-in">
                            <div class="become-img">
                                <img src="../assets/images/tick-square.svg" alt="">
                            </div>
                            <div class="become-text">
                                <h3>Teamwork</h3>
                                <p>At Seattle Choice Transport, we believe that teamwork makes our dream work. The quality of our teamwork gets us operating with ease</p>
                            </div>
                        </div>
                    </div>
                    <div class="navigation-button why-work-btn">
                        <a href="https://intelliapp.driverapponline.com/c/seattlechoicetransport" target="_blank">Apply now</a>
                    </div>
                    <p>Want to learn more about Seattle Choice? <a @click="goToAbout()">Click here</a></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BecomeDriver',
    methods: {
        goToAbout(){
            this.$router.push({ path: "/about" });
        },
    }
}
</script>